.ModalToggleButton {
    position: fixed;
    top: 20px;
    right: 20px;
    height: 27px;
    width: 35px;
    cursor: pointer;
    z-index: 9999;
    transition: opacity .25s ease;
}
.ModalToggleButton:hover {
    opacity: .7;
}

.ModalToggleButton span {
    background: #fff;
    border: none;
    height: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .35s ease;
    cursor: pointer;
}

.ModalToggleButton.active .top {
    -webkit-transform: translateY(11px) translateX(0) rotate(45deg);
    -ms-transform: translateY(11px) translateX(0) rotate(45deg);
    transform: translateY(11px) translateX(0) rotate(45deg);
    background: #FFF;
}
.ModalToggleButton.active .middle {
    opacity: 0;
    background: #FFF;
}
.ModalToggleButton.active .bottom {
    -webkit-transform: translateY(-11px) translateX(0) rotate(-45deg);
    -ms-transform: translateY(-11px) translateX(0) rotate(-45deg);
    transform: translateY(-11px) translateX(0) rotate(-45deg);
    background: #FFF;
}
.ModalToggleButton span:nth-of-type(2) {
    top: 11px;
}
.ModalToggleButton span:nth-of-type(3) {
    top: 22px;
}