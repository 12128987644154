@-webkit-keyframes fadeInRight {
    0% {
        opacity: 0;
        left: 20%;
    }
    100% {
        opacity: 1;
        left: 0;
    }
}


@keyframes fadeInRight {
    0% {
        opacity: 0;
        left: 20%;
    }
    100% {
        opacity: 1;
        left: 0;
    }
}


.Overlay {
    position: fixed;
    background: rgba(0,0,0,0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity .35s, visibility .35s, height .35s;
    overflow: hidden;
    z-index: 999;
}
.Overlay.open {
    opacity: .9;
    visibility: visible;
    height: 100%;
}

.Overlay.open li {
    -webkit-animation: fadeInRight .5s ease forwards;
    -webkit-animation-delay: .35s;
    animation: fadeInRight .5s ease forwards;
    animation-delay: .35s;
}
.Overlay.open li:nth-of-type(2) {
    -webkit-animation-delay: .4s;
    animation-delay: .4s;
}
.Overlay.open li:nth-of-type(3) {
    -webkit-animation-delay: .45s;
    animation-delay: .45s;
}
.Overlay.open li:nth-of-type(4) {
    -webkit-animation-delay: .50s;
    animation-delay: .50s;
}
.Overlay.open li:nth-of-type(5) {
    -webkit-animation-delay: .55s;
    animation-delay: .55s;
}

.Overlay nav {
    position: relative;
    height: 70%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 30px;
    line-height: 30px;
    font-family: highway_gothic_expandedRg, sans-serif;
    font-weight: 400;
    text-align: center;
}
.Overlay ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;
}
.Overlay ul li {
    display: block;
    height: calc(100% / 7);
    min-height: 50px;
    position: relative;
    opacity: 0;
}
.Overlay ul li a {
    display: block;
    position: relative;
    color: #FFF;
    letter-spacing: 8px;
    text-transform: uppercase;
    text-decoration: none;
    overflow: hidden;
    padding-bottom: 5px;
}

.Overlay ul li.overlaySocials {
    padding-top: 30px;
}
.Overlay ul li.overlaySocials a {
    display: inline;
    padding: 0 10px;
}

.Overlay ul li a:hover:after, .overlay ul li a:focus:after, .overlay ul li a:active:after {
    width: 100%;
}

/* desktop */
@media (min-device-width: 1200px) {
    .Overlay ul li a:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        height: 4px;
        background: #FFF;
        transition: .35s;
    }
    .Overlay nav {
        font-size: 40px;
        line-height: 40px;
    }
}
