html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'highway_gothic_expandedRg';
  src: url('../fonts/hwygexpd-webfont.woff2') format('woff2'),
  url('../fonts/hwygexpd-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal
}

@font-face {
  font-family: 'roboto_light';
  src: url('../fonts/Roboto-Light.woff') format('woff'),
  url('../fonts/Roboto-Light.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@keyframes rotation {
  25% {
    transform: rotate(-10deg);
    -webkit-box-shadow: -3px 3px 5px 3px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    -3px 3px 5px 3px #ccc;  /* Firefox 3.5 - 3.6 */
    box-shadow:         -3px 3px 5px 3px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  }
  50% {
    transform: rotate(10deg);
    -webkit-box-shadow: 3px 3px 5px 3px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    3px 3px 5px 3px #ccc;  /* Firefox 3.5 - 3.6 */
    box-shadow:         3px 3px 5px 3px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes rotation {
  25% {
    transform: rotate(-10deg);
    -webkit-box-shadow: -3px 3px 5px 3px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    -3px 3px 5px 3px #ccc;  /* Firefox 3.5 - 3.6 */
    box-shadow:         -3px 3px 5px 3px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  }
  50% {
    transform: rotate(10deg);
    -webkit-box-shadow: 3px 3px 5px 3px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow:    3px 3px 5px 3px #ccc;  /* Firefox 3.5 - 3.6 */
    box-shadow:         3px 3px 5px 3px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  }
  100% {
    transform: rotate(0deg);
  }
}

.App {
  text-align: center;
}

html {
  height: 100%;
  /*overflow: hidden;*/
}

body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.Main {
  overflow: hidden;
  background-image: url('../images/dsfBackground.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  position: relative;
}
.bandTitle {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 400px;
}

.mailingList {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.aboutHeader {
  background-color: #324451;
  font-family: highway_gothic_expandedRg, Georgia, serif;
  color: #FFF;
  padding: 25px 40px 0;
  text-align: center;
  font-size: 20px;
  letter-spacing: 2px;
}

.aboutContent {
  /*background-image: linear-gradient(#324451, #9dbdca);*/
  background-color: #324451;
  font-family: roboto_light, Georgia, serif;
  color: #FFF;
  padding: 25px 40px 40px;
  text-align: left;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 1px;
}

.aboutContent .aboutTextContainer {
  width: 50%;
  padding: 10px;
  margin: auto;
}

.Music {
 background-color: #416d73;
}
.musicHeader {
  font-family: highway_gothic_expandedRg, Georgia, serif;
  color: #FFF;
  padding: 25px 40px 0;
  text-align: center;
  font-size: 20px;
  letter-spacing: 2px;
}

.musicContent {
  font-family: highway_gothic_expandedRg, Georgia, serif;
  color: #FFF;
  padding: 25px 40px 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.Instagram {
  background-color: #d9e6f1;
}

.instagramHeader {
  font-family: highway_gothic_expandedRg, Georgia, serif;
  color: #3c5164;
  padding: 40px 40px 0;
  text-align: center;
  font-size: 20px;
  letter-spacing: 2px;
}

.instagramContent {
  font-family: highway_gothic_expandedRg, Georgia, serif;
  color: #3c5164;
  padding: 25px 40px 40px;
  text-align: center;
  display: flex;
  flex-direction: row;
}

.footerContent {
  background-color: #e2ecf4;
  font-family: highway_gothic_expandedRg, Georgia, serif;
  color: #3c5164;
  /*padding: 200px 0 200px;*/
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.azimPortrait {
  width: 300px;
  height: 300px;
}

.referral {
  display: none !important;
}

.musicPlatformsContainer {
  font-size: 40px;
  display: inline;
  height: calc(100% / 7);
  min-height: 50px;
  position: relative;
  padding-top: 20px;
}

.musicPlatformsContainer a {
  display: inline;
  position: relative;
  color: #FFF;
  letter-spacing: 8px;
  text-transform: uppercase;
  text-decoration: none;
  overflow: hidden;
  padding: 0 10px;
}


.SocialPlatforms {
  text-align: center;
  display: block;
  max-width: 300px;
  position: relative;
  margin: 30px auto 40px auto;
}

.SocialPlatforms a {
  font-size: 20px;
  letter-spacing: 2px;
  color: #3a5064;
  padding: 7px;
  margin: 10px auto;
  display: block;
  text-transform: uppercase;
}

.footerLinks {
  max-width: 250px;
  display: block;
  text-align: center;
  margin: 20px auto 0 auto;
  padding-bottom: 30px;
}

.footerLinks a {
  font-size: 15px;
  letter-spacing: 1px;
  padding: 5px;
  margin: 10px auto;
  display: block;
  color: #3a5064;
  text-transform: uppercase;
}


a, a:hover {
  text-decoration: none;
}

.signupContainer {
  height: 0;
  transition: height 1.2s ease-in-out;
  background-color: yellow;
  position: relative;
}

.closeContainer {
  position: absolute;
  bottom: 20px;
  left: 50%;
  cursor: pointer;
}

.signupFormDisabled {
  display: none;
}

.signupFormOpen {
  height: 100vh;
}

.fullHeight {
  height: 100vh;
}


/* desktop */
@media (min-device-width: 1200px) {

}

/* landscape phone */
@media (orientation: landscape) and (min-device-width: 480px) and (max-device-width: 812px) {
  .bandTitle {
    margin-top: 40px;
    transition: margin 700ms;
  }
  .bandTitle.low {
    margin-top: 200px;
  }
}

/* portrait phone */
@media (orientation: portrait) and (max-width: 500px) {
  .bandTitle {
    margin-top: 180px;
    transition: margin 700ms;
  }
  .bandTitle.low {
    margin-top: 400px;
  }
  .aboutContent {
    flex-direction: column-reverse;
  }
  .musicContent {
    flex-direction: column;
  }
  .instagramContent {
    flex-direction: column;
  }
  .aboutContent .aboutTextContainer {
    width: 90%;
  }

}

