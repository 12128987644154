.mailingListHeader {
    position: relative;
    background-color: #324451;
    font-family: highway_gothic_expandedRg, Georgia, serif;
    color: #FFF;
    padding: 15px 40px 0;
    text-align: center;
    font-size: 20px;
    letter-spacing: 2px;
}

.mailingListContent {
    position: relative;
    /*background-image: linear-gradient(#324451, #9dbdca);*/
    background-color: #324451;
    font-family: roboto_light, Georgia, serif;
    color: #FFF;
    padding: 10px 54.75px 15px;
    text-align: left;
    display: flex;
    flex-direction: row;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 1px;
}

.mailingListForm {
    flex: 2;
    display: flex;
    flex-direction: row;
    /*justify-content: flex-end;*/
    /*display: flex;*/
    /*grid-column: 2;*/
}

.MailingList {
    visibility: hidden;
}

.submitPrompt {
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-family: roboto_light, Georgia, serif;
    color: #FFF;
    font-size: 22px;
}

.MailingList.visible {
    visibility: visible;
}

.MailingListBackground {
    background-color: #324451;
    opacity: 0.0;
    transition: opacity 2.2s ease-out;
}
.MailingListBackground.visible {
    opacity: 0.9;
}

.mailingListForm .submitButtonContainer {
    flex: 1;
    align-self: center;
    text-align: center;
    padding: 0 25px;
}

.submitButtonContainer button {
    background-color: #cfc7b5;
    border: none;
    color: #324450;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
}
.mailingListForm .inputFieldContainer {
    flex: 2;
    padding: 0 25px;

}

.inputFieldContainer input {
    width: 100%;
    height: 28px;
    font-size: 24px;
}

.inputFieldContainer .emailFieldContainer {
    margin-top: 10px;
}

.validationMessage {
    color: #e69f9f;
    font-size: 12px;
}

.mailingListDescription {
    flex: 1;
    align-self: center;
    padding: 0 20px;
    /*flex-grow: 1;*/
}

.mailingListCloseButton {
    position: absolute;
    top: 5px;
    right: 20px;
    cursor: pointer;
}

.validationMessage.hidden {
    display: none;
}

@media (orientation: portrait) and (max-width: 500px) {
    .mailingListContent {
        flex-direction: column-reverse;
        padding: 10px;
    }
    .mailingListDescription {
        margin-top: 10px;
        text-align: center;
        font-size: 12px;
        padding-bottom: 66px;
    }
    .mailingListForm {
        flex-direction: column;
    }
    .submitButtonContainer {
        margin-top: 15px;
        text-align: left;
    }

}