@keyframes MoveUpDown {
    0%, 100% {
        bottom: -10px;
    }
    50% {
        bottom: 10px;
    }
}

@-webkit-keyframes MoveUpDown {
    0%, 100% {
        bottom: -10px;
    }
    50% {
        bottom: 10px;
    }
}

.upArrows {
    /*animation: MoveUpDown 600ms linear infinite;*/
    position: relative;
    left: 0;
    bottom: 0;
}

.featuredTrackImage {
    width: 150px;
    height: 150px;
}
.featuredTrack {
    position: relative;
    /*animation: rotation 1.5s infinite linear;*/
    box-shadow: 0 0 2px 1px rgba(256, 256, 256, 0.4);
    display: inline-block;
}
.featuredTrackContainer {
    position: relative;
    display: inline-block;
    margin-bottom: 30px;
}
.latestReleaseTitle {
    margin-bottom: 20px;
    font-family: roboto_light, Georgia, serif;
    font-size: 18px;
    line-height: 23px;
    font-weight: bold;
    position: relative;
}
